import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Footer(){
    const[windewWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    function handleResive(){
        setWindowWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleResive)
        chengeFooter()

        return() => {
            window.removeEventListener('resize', handleResive)
        }
    }, [])

    useEffect(() => {
        chengeFooter()
    }, [windewWidth])

    function chengeFooter(){
        if(windewWidth < 400){
            document.getElementsByClassName('about_part')[0].style.gridTemplateColumns = 'repeat(1, 100%)'
        }else if(windewWidth >= 400 && windewWidth < 550){
            document.getElementsByClassName('about_part')[0].style.gridTemplateColumns = 'repeat(2, 50%)'
            document.getElementsByClassName('about_part')[0].children[0].style.paddingLeft = '60px'
            document.getElementsByClassName('about_part')[0].children[1].style.paddingLeft = '60px'
            document.getElementsByClassName('about_part')[0].children[2].style.paddingLeft = '60px'
        }else if(windewWidth >= 550 && windewWidth < 750){
            document.getElementsByClassName('about_part')[0].style.gridTemplateColumns = 'repeat(2, 50%)'
            document.getElementsByClassName('about_part')[0].children[0].style.paddingLeft = '80px'
            document.getElementsByClassName('about_part')[0].children[1].style.paddingLeft = '80px'
            document.getElementsByClassName('about_part')[0].children[2].style.paddingLeft = '80px'
        }else if(windewWidth >= 750 && windewWidth < 1100){
            document.getElementsByClassName('about_part')[0].style.gridTemplateColumns = 'repeat(3, 33.3%)'
            document.getElementsByClassName('about_part')[0].children[0].style.paddingLeft = '60px'
            document.getElementsByClassName('about_part')[0].children[1].style.paddingLeft = '60px'
            document.getElementsByClassName('about_part')[0].children[2].style.paddingLeft = '60px'
        }else if(windewWidth >= 1100){
            document.getElementsByClassName('about_part')[0].style.gridTemplateColumns = 'repeat(3, 33.3%)'
            document.getElementsByClassName('about_part')[0].children[0].style.paddingLeft = '100px'
            document.getElementsByClassName('about_part')[0].children[1].style.paddingLeft = '100px'
            document.getElementsByClassName('about_part')[0].children[2].style.paddingLeft = '100px'
        }
    }

    function onFilterRedirect(type){
        document.cookie = `filter=${type}; path=/; max-age=60`
        navigate('/')
    }


    return(
        <footer>
            <div className="about_part">
                <div>
                    <h2>КОМПАНИЯ</h2>
                    <Link to='/faq'><div>О Poizon</div></Link>
                    <div>Команда</div>
                    <div>Контакты</div>
                </div>
                <div className="markable_column">
                    <h2>КАТАЛОГ</h2>
                    <div onClick={() => onFilterRedirect('shoes')}>Обувь</div>
                    <div onClick={() => onFilterRedirect('tshorts')}>Футболки</div>
                    <div onClick={() => onFilterRedirect('accessories')}>Аксессуары</div>
                    <div onClick={() => onFilterRedirect('bags')}>Сумки</div>
                    <div onClick={() => onFilterRedirect('hoodies')}>Кофты</div>
                    <div onClick={() => onFilterRedirect('pants')}>Штаны</div>
                    <div onClick={() => onFilterRedirect('shorts')}>Шорты</div>
                    <div onClick={() => onFilterRedirect('underpants')}>Нижнее бельё</div>
                    <div onClick={() => onFilterRedirect('jackets')}>Верхняя одежда</div>
                    <div onClick={() => onFilterRedirect('skirts')}>Юбки</div>
                </div>
                <div>
                    <h2>МАГАЗИН</h2>
                    <Link to='/faq'><div>Оплата</div></Link>
                    <Link to='/faq'><div>Доставка</div></Link>
                    <Link><div>Помощь</div></Link>
                    <Link to='/faq'><div>Грантия и безопасность</div></Link>
                    <Link to='/faq'><div>Проверка на оригинальность</div></Link>
                    <Link to='/faq?page=2'><div>Как выбрать размер</div></Link>
                    <Link to='/faq'><div>Обмен и возврат</div></Link>
                </div>
            </div>
            <div className="docs_part">
                <span>KODR®</span>
                <span>Все права защищены</span>
                <span>Политика конфиденциальности</span>
                <span>Оферта</span>
            </div>
        </footer>
    )
}

export default Footer;