import React, { useEffect, useRef, useState } from "react";
import { axiosAsk, getCookie } from "../../api/api";
import '../../css/Catalog.css';

import ProdCard from "./StackItems/ProdCard";
import InfiniteScroll from 'react-infinite-scroll-component';

const Catalog = ({setError, prodsIds, prods, setProds, catalogPosition, setCatalogPosition, scrollPosition, setScrollPosition, setWait, getScrollParent}) => {
    const[deletedItems, setDeletedItems] = useState([]);
    const[scrollTreshold, setScrollTreshold] = useState(0.6);
    const[catalogPositionDoubler, setCatalogPositionDoubler] = useState(0);

    const[loadPermition, setLoadPermition] = useState(false);
    const[unloadPermition, setUnloadPermition] = useState(null);

    useEffect(() => {
        document.getElementById('scroll_wrapper').scrollTo({top: scrollPosition})
    }, [])



    function getProductsBatch(ids){
        if(prods.length != 0 && ids.indexOf(prods[prods.length-1].article[0]) != -1){
            return
        }

        axiosAsk({
            oper:'get_item_info_by_id',
            ask_articles:ids,
            flag:true,
            chat_id:getCookie('user_id')
        })
        .then(response => {
            setProds([...prods, ...response.data['list']])
        })
        .catch(response => {setError(true); console.error(response)})
        .finally(() => {
            setWait(false)
            setLoadPermition(false)
        })
    }
    useEffect(() => {
        console.log('change id')
        if(prodsIds != undefined && prodsIds.length != 0 && scrollPosition < 1000){
            if(prods.length > 0){
                console.log('change id 1')
                setProds([])
                setDeletedItems([])
                setCatalogPosition(-1)
            }else{
                console.log('change id 2')
                setProds([])
                setDeletedItems([])
                setCatalogPosition(0)
            }
        }
    }, [prodsIds])
    function getNeededIdsArray(catalog_position){
        if(prodsIds.length === undefined || prodsIds.length === 0 || catalog_position < 0){
            return
        }

        if(catalog_position === 0){
            const ids = prodsIds.slice((0)*28, (prodsIds.length > 1*28 ?1*28 :prodsIds.length))
            getProductsBatch(ids)
        }else{
            const ids = prodsIds.slice(catalog_position*28, (prodsIds.length > (catalog_position+1)*28 ?(catalog_position+1)*28 :prodsIds.length))
            getProductsBatch(ids)
        }
    }
    useEffect(() => {
        if(loadPermition){
            console.log('load permition', catalogPosition+1)
            setCatalogPosition(catalogPosition+1)
        }
    }, [loadPermition])
    useEffect(() => {
        console.log(catalogPosition, scrollPosition, document.getElementById('scroll_wrapper').scrollTop)
        if(catalogPosition == -1){
            setCatalogPosition(0)
        }else{
            getNeededIdsArray(catalogPosition)
        }
    }, [catalogPosition])

    // Логика для подгрузки следующих товаров
    const fetchMoreData = () => {
        console.log('add prod')
        if(document.getElementById('catalog') == undefined){
            return
        }

        if(document.getElementById('catalog').children[0] == undefined){
            return
        }

        if((prods.length + deletedItems.length) < prodsIds.length){
            setWait(true)
            setLoadPermition(true)
        }
    };

    // // Логика для удаления верхних элементов
    // const handleScroll = () => {
    //     if(document.getElementById('scroll_wrapper').scrollTop > 3500){
    //         setUnloadPermition(-1)
    //     }
    // };

    // // Восстановление удаленных элементов при прокрутке вверх
    // const restoreDeletedItems = () => {
    //     if(document.getElementById('scroll_wrapper').scrollTop < 1700 && deletedItems.length >= 0){
    //         setUnloadPermition(1)
    //     }
    // };

    useEffect(() => {
        if(unloadPermition === -1){
            AddNDellItems('down')
        }else if(unloadPermition === 1){
            AddNDellItems('up')
        }
    }, [unloadPermition])
    function AddNDellItems(direction){
        let RemoveProds = []
        let AddProds = []
        if(direction === 'up' && deletedItems.length > 0){
            // Восстановление удаленных товаров
            for(let i = deletedItems.length - 1; i >= 0; i--){
                if(AddProds.length < (window.innerWidth > 499 && window.innerWidth < 700 ?3 :4)){
                    AddProds.push(deletedItems[i]);
                } else {
                    RemoveProds.push(deletedItems[i]);
                }
            }
            AddProds.reverse()
            RemoveProds.reverse()
            const newAddProdsList = AddProds.filter(item => !prods.some(existingItem => (existingItem.article[0] === item.article[0])));
            setProds(prevItems => [...newAddProdsList, ...prevItems]);
            setDeletedItems(RemoveProds);
        }else if(direction === 'down'){
            // Удаление верхних товаров
            for(let i = 0; i < prods.length; i++){
                if(i < (window.innerWidth > 499 && window.innerWidth < 700 ?3 :4)){
                    RemoveProds.push(prods[i]);
                } else {
                    AddProds.push(prods[i]);
                }
            }
            const newDelProdsList = RemoveProds.filter(item => !deletedItems.some(existingItem => existingItem.article[0] === item.article[0]))
            setProds(AddProds);
            setDeletedItems(prevDeleted => [...prevDeleted, ...newDelProdsList]);
        }
        setUnloadPermition(0)
    }




    useEffect(() => {
        const scroll_wrapper = document.getElementById('scroll_wrapper')
        scroll_wrapper.addEventListener('scroll', setTreshold);
        // scroll_wrapper.addEventListener('scroll', handleScroll);
        // scroll_wrapper.addEventListener('scroll', restoreDeletedItems);
        return () => {
            scroll_wrapper.removeEventListener('scroll', setTreshold);
        //     scroll_wrapper.removeEventListener('scroll', handleScroll);
        //     scroll_wrapper.removeEventListener('scroll', restoreDeletedItems);
        };
    }, [prods, deletedItems]);

    function setTreshold(){
        if(document.getElementById('catalog').children[0] == undefined){
            return
        }

        setScrollPosition(document.getElementById('scroll_wrapper').scrollTop)
        setScrollTreshold((document.getElementById('catalog').children[document.getElementById('catalog').children.length - 1].offsetTop-2500)/document.getElementById('catalog').children[document.getElementById('catalog').children.length - 1].offsetTop)
    }



    return (
        <InfiniteScroll
            dataLength={prods.length}
            next={fetchMoreData}
            hasMore={true}
            scrollThreshold={scrollTreshold}
            getScrollParent={getScrollParent}
            scrollableTarget="scroll_wrapper"
        >
            <div className="catalog" id='catalog'>
                {prods.map((prod) => (
                    <div key={prod.article}>{<ProdCard info={prod} setError={setError}/>}</div>
                ))}
            </div>
        </InfiniteScroll>
    );
};





export default Catalog;