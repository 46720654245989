import { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import classes from './css/General.css';
import axios from 'axios';

import Header from './components/Parts/MainItems/Header';
import Footer from './components/Parts/MainItems/Footer';

import Page404 from './components/Pages/Page404';
import ProductPage from "./components/Pages/ProductPage";
import AccountPage from "./components/Pages/AccountPage";
import LoginPage from "./components/Pages/LoginPage";
import CatalogPage from './components/Pages/CatalogPage';
import CartPage from './components/Pages/CartPage';
import FavoritesPage from './components/Pages/FavoritesPage';
import CommentPage from './components/Pages/CommentPage';
import OrderPage from './components/Pages/OrderPage';
// import SearchPage from './components/Pages/SearchPage';
// import UpdatePassPage from './components/Pages/UpdatePassPage';
import FuckQPage from './components/Pages/FuckYouPage';

function App() {
    const[prodsIds, setProdsIds] = useState([]);
    const[products, setProducts] = useState([]);
    const[scrollPosition, setScrollPosition] = useState(0);
    const[catalogPosition, setCatalogPosition] = useState(null);

    const[searchText, setSearchText] = useState(null);
    const scrollWrapRef = useRef(null);
    const getScrollParent = () => {
        return scrollWrapRef.current; // Возвращаем текущий элемент
    };

    return (
        <BrowserRouter>
            <div className='scroll_wrapper' id='scroll_wrapper' ref={scrollWrapRef}>
                <div className='wrapper'>
                    <Header Apply={setSearchText}/>
                    <main>
                        <Routes>
                            {/*
                            <Route path='/update_pass' element={<UpdatePassPage SRVRADDRESS={SRVRADDRESS}/>} />
                            */}
                            <Route path='error_404' element={<Page404/>}/>
                            <Route path='*' element={<Page404/>}/>

                            <Route path="/product" element={<ProductPage/>}/>
                            <Route path='/product/comments' element={<CommentPage/>}/>
                            <Route path="/" element={<CatalogPage
                                searchText={searchText}
                                prodsIds={prodsIds}
                                prods={products}
                                scrollPosition={scrollPosition}
                                catalogPosition={catalogPosition}
                                setProdsIds={setProdsIds}
                                setProds={setProducts}
                                setScrollPosition={setScrollPosition}
                                setCatalogPosition={setCatalogPosition}
                                getScrollParent={getScrollParent}
                            />}/>

                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/profile" element={<AccountPage/>}/>
                            <Route path='/cart' element={<CartPage/>}/>
                            <Route path='/favorites' element={<FavoritesPage/>}/>
                            
                            <Route path='/checkout' element={<OrderPage/>} />
                            <Route path='/faq' element={<FuckQPage/>} />
                        
                        </Routes>
                    </main>
                </div>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;